/* @flow */
/* eslint-disable max-len */
import sjcl from "sjcl";
import moment from "moment";
import { CognitoUser } from "amazon-cognito-identity-js";
import config from "../config";
import getCognitoUserPool from "../cognito/getCognitoUserPool";
import getCognitoUser from "../cognito/getCognitoUser";
import userIsSpaCM from "../cognito/isSpaCM";

function getUserCredentials() {
  return JSON.parse(
    localStorage.getItem(config.localStorageAuthenticatedUserKey) || "null"
  );
}

function isSessionValid(userToken) {
  const now = moment().utc();
  const payload = userToken.split(".")[1];
  const idtokenExpiration = JSON.parse(
    sjcl.codec.utf8String.fromBits(sjcl.codec.base64.toBits(payload))
  );
  return now.isBefore(moment.unix(idtokenExpiration.exp));
}

export function removeExtraAttributesFromToken(token) {
  return token
    .replace(/_BACKSTOPPINGMODE_/g, "")
    .replace(/_POKKETUSERSPAMODE_/g, "")
    .replace(/_POKKETUSERCMMODE_/g, "")
    .replace(/_DEPENDENTMODE_/g, "");
}

function addExtraAttributesToToken(token) {
  const backstoppingId = localStorage.getItem("backstoppingId");
  const dependentId = localStorage.getItem("dependentId");
  const dependentParticipantId = localStorage.getItem("dependentParticipantId");
  const isCurrentlyBackstopping = backstoppingId;
  const isCurrentlyActingOnBehalfOf = dependentId;
  const url = window.location.href;
  let isOnParticipantRoute = false;
  let isOnSPARoute = false;

  const pIdentifier = "/#/me";
  const participantCheckIndex = url.indexOf(pIdentifier);

  if (participantCheckIndex !== -1) {
    const participantCheck = url.slice(
      participantCheckIndex + pIdentifier.length
    );
    if (participantCheck.length === 0 || participantCheck.charAt(0) === "/") {
      isOnParticipantRoute = true;
    }
  }

  const spaIdentifier = "/#/admin";
  const spaCheckIndex = url.indexOf(spaIdentifier);

  if (spaCheckIndex !== -1) {
    const spaCheck = url.slice(spaCheckIndex + spaIdentifier.length);
    if (spaCheck.length === 0 || spaCheck.charAt(0) === "/") {
      isOnSPARoute = true;
    }
  }

  const isOnCmRoute = !isOnParticipantRoute && !isOnSPARoute;

  // if the user has SPA and CM roles
  // attach a specific pattern to the end of the token
  // depending on whether the user in on a SPA route or
  // CM route. This pattern will be evaluated by
  // the CustomAuthorizer lambda. The CustomAuthorizer lambda
  // will then verify role membership on the server and if valid
  // will set this role (SPA or CM) for the AWS principal.
  if (userIsSpaCM()) {
    if (isCurrentlyBackstopping) {
      localStorage.setItem("backstopping", 1);
      return `${token}_POKKETUSERCMMODE__BACKSTOPPINGMODE_${backstoppingId}`;
    }
    if (isOnSPARoute) {
      return `${token}_POKKETUSERSPAMODE_`;
    }
    if (isOnCmRoute) {
      return `${token}_POKKETUSERCMMODE_`;
    }
    return token;
  }
  if (isCurrentlyBackstopping) {
    localStorage.setItem("backstopping", 1);
    return `${token}_BACKSTOPPINGMODE_${backstoppingId}`;
  }
  if (isCurrentlyActingOnBehalfOf) {
    localStorage.setItem("actingOnBehalfOf", 1);

    return `${token}_DEPENDENTMODE_${dependentId}:${dependentParticipantId}`;
  }
  return token;
}

export function token() {
  const authenticatedUser = getUserCredentials();
  if (authenticatedUser) {
    const authToken = authenticatedUser.token;
    if (authToken && isSessionValid(authToken)) {
      return addExtraAttributesToToken(authToken);
    }
    return null;
  }
  return null;
}

export function getUserRoles(): ?string {
  const authenticatedUser = getUserCredentials();
  if (authenticatedUser) {
    return authenticatedUser.roles;
  }
  return null;
}

export function getDefaultRole(): ?string {
  return localStorage.getItem("defaultRole");
}

export function getUserId(): ?string {
  const authenticatedUser = getUserCredentials();
  if (authenticatedUser) {
    return authenticatedUser.userId;
  }
  return null;
}

export function getUserRolesFromUserAttributes() {
  return new Promise((resolve, reject) => {
    const cognitoUser = getCognitoUserPool().getCurrentUser();
    cognitoUser.getUserAttributes((err, result) => {
      if (err) {
        reject(err);
      }
      let roles = "";
      for (let i = 0; i < result.length; i += 1) {
        if (result[i].getName() === "custom:roles") {
          roles = result[i].getValue();
        }
      }
      resolve(roles);
    });
  });
}

export function getCurrentSession() {
  const that = this;
  return new Promise((resolve, reject) => {
    const userPool = getCognitoUserPool();
    if (!userPool.getCurrentUser()) {
      reject("cognitouser not valid");
    } else {
      const userData = {
        Username: userPool.getCurrentUser().username,
        Pool: userPool,
      };
      const cognitoUser = new CognitoUser(userData);
      cognitoUser.getSession((err, session) => {
        if (err) {
          reject("cannot get session");
        } else if (session.isValid()) {
          const roles = getUserRoles();
          const userId = getUserId();
          const authenticatedUser = JSON.stringify({
            token: session.idToken.jwtToken,
            roles,
            userId,
          });
          localStorage.setItem(
            config.localStorageAuthenticatedUserKey,
            authenticatedUser
          );
          resolve(session);
        } else {
          reject("invalid session");
        }
      });
    }
  });
}

export function logoutUser(nextState: any, replace: any) {
  // eslint-disable-line
  const cognitoUser = getCognitoUserPool().getCurrentUser();
  localStorage.removeItem(config.localStorageAuthenticatedUserKey);
  localStorage.removeItem("backstopping");
  localStorage.removeItem("backstoppingId");
  localStorage.removeItem("backstoppedCM");
  localStorage.removeItem("backstoppedCMId");
  localStorage.removeItem("defaultRole");
  localStorage.removeItem("switchmode");
  localStorage.removeItem("timeOutAt");
  localStorage.removeItem("isGuardian");
  localStorage.removeItem("actingOnBehalfOfDependent");
  localStorage.removeItem("dependentParticipantId");
  localStorage.removeItem("dependentId");
  localStorage.removeItem("actingOnBehalfOf");
  localStorage.removeItem("guardianParticipantId");
  localStorage.removeItem("accessToken");
  localStorage.removeItem("idToken");

  if (cognitoUser !== null) {
    cognitoUser.signOut();
  }
}

export function getCurrentUser() {
  const userPool = getCognitoUserPool();
  return getCognitoUser(userPool.getCurrentUser().username);
}
